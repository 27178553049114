(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/svenskaspel-backbone-router/assets/javascripts/router.js') >= 0) return;  svs.modules.push('/components/app/svenskaspel-backbone-router/assets/javascripts/router.js');


(function(svs, Backbone, _) {
  'use strict';

  Backbone.Router.pushView = function() {
    throw new Error('Obsolete! Use your own view handling.');
  };

  var originalRoute = Backbone.Router.prototype.route;

  var originalNavigate = Backbone.Router.prototype.navigate;

  var nop = function() { };

  function compilePattern(text, model) {
    var matches = text.match(/({{([\w-]*)}})+/g);

    if (matches) {
      for (var i = 0, length = matches.length; i < length; i++) {
        text = text.replace(
          new RegExp(matches[i], 'g'),
          model ? model[matches[i].replace(/{|}/g, '')] || '' : ''
        );
      }
    }

    return text;
  }

  function updateTitle(routePattern, titleModel) {
    if (svs.core.data.routesMetaData) {
      var route = svs.core.data.routesMetaData[routePattern];

      if (route) {
        var title = svs.core.data.routesMetaData[routePattern].title;

        if (title) {
          titleModel = titleModel || {};
          titleModel.moduleTitle = svs.core.data.routesMetaData[''] && svs.core.data.routesMetaData[''].title;
          $('title').text(compilePattern(title, titleModel));
        }
      }
    }
  }

  function updateMetaDescription(routePattern, model) {
    if (svs.core.data.routesMetaData) {
      var route = svs.core.data.routesMetaData[routePattern];

      if (route) {
        var description =
          svs.core.data.routesMetaData[routePattern].metaDescription;

        if (description) {
          $('meta[name=description]').attr(
            'content',
            compilePattern(description, model)
          );
        }
      }
    }
  }

  function updateMetaKeywords(routePattern, model) {
    if (svs.core.data.routesMetaData) {
      var route = svs.core.data.routesMetaData[routePattern];

      if (route) {
        var keywords = svs.core.data.routesMetaData[routePattern].metaKeywords;

        if (keywords) {
          $('meta[name=keywords]').attr(
            'content',
            compilePattern(keywords, model)
          );
        }
      }
    }
  }

  _.extend(Backbone.Router.prototype, {
    oldRouteToRegExp: Backbone.Router.prototype._routeToRegExp,

    _routeToRegExp: function(route) {
      route = this.oldRouteToRegExp.call(this, route);
      return new RegExp(route.source, 'i'); 
    },

    before: nop,

    after: nop,

    route: function(route, name, callback) {
      if (!callback) {
        callback = this[name];
      }

      var verifyLogin = function(scope, args, callback) {
        if (svs.core.data.routesMetaData) {
          var routeMetaData = svs.core.data.routesMetaData[route];

          if (
            routeMetaData &&
            routeMetaData.roles &&
            routeMetaData.roles.indexOf(svs.core.userSession.roles.PLAYER) > -1
          ) {
            if (
              !svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)
            ) {
              svs.components.customer_login.login();
            } else {
              callback.apply(scope, args);
            }
          } else {
            callback.apply(scope, args);
          }
          svs.core.data.roles =
            routeMetaData && routeMetaData.roles ?
              routeMetaData.roles.slice() :
              [];
        } else {
          callback.apply(scope, args);
        }
      };

      var wrappedCallback = _.bind(function() {
        var callbackArgs = [route, _.toArray(arguments)];
        var beforeCallback;

        if (_.isFunction(this.before)) {
          beforeCallback = this.before;
        } else if (typeof this.before[route] !== 'undefined') {
          beforeCallback = this.before[route];
        } else {
          beforeCallback = nop;
        }

        if (beforeCallback.apply(this, callbackArgs) === false) {
          return;
        }

        if (callback) {
          verifyLogin(this, arguments, callback);
        }

        updateTitle(this.current().pattern, svs.core.data.title);
        updateMetaDescription(
          this.current().pattern,
          svs.core.data.metaDescription
        );
        updateMetaKeywords(this.current().pattern, svs.core.data.metaKeywords);

        var afterCallback;
        if (_.isFunction(this.after)) {
          afterCallback = this.after;
        } else if (typeof this.after[route] !== 'undefined') {
          afterCallback = this.after[route];
        } else {
          afterCallback = nop;
        }

        afterCallback.apply(this, callbackArgs);
      }, this);

      return originalRoute.call(this, route, name, wrappedCallback);
    },

    current: function() {
      var router = this;
      var fragment = Backbone.history.fragment;
      var routes = _.pairs(router.routes);
      var route = null;
      var params = null;
      var matched;
      var pattern = null;

      matched = _.find(routes, function(handler) {
        route = _.isRegExp(handler[0]) ?
          handler[0] :
          router._routeToRegExp(handler[0]);

        if (route.test(fragment)) {
          pattern = handler[0];
          return true;
        }

        return false;
      });

      if (matched) {
        params = router._extractParameters(route, fragment);
        route = matched[1];
      }

      return {
        route: route,
        fragment: fragment,
        params: params,
        pattern: pattern
      };
    },

    navigate: function(fragment, trigger) {
      var navigateResult = originalNavigate.call(this, fragment, trigger);

      updateTitle(this.current().pattern, svs.core.data.title);
      updateMetaDescription(
        this.current().pattern,
        svs.core.data.metaDescription
      );
      updateMetaKeywords(this.current().pattern, svs.core.data.metaKeywords);

      return navigateResult;
    }
  });
})(svs, Backbone, _);


 })(window);